export default class CustomFieldHelper {
  private fields: { key: string; value: string }[]

  constructor(customfields?: string | null) {
    this.fields = []
    if (customfields !== undefined && customfields !== null && customfields !== '') {
      const data = JSON.parse(customfields.replace(/'/g, '"'))
      for (const key in data) {
        this.fields.push({ key, value: data[key] })
      }
    }
  }

  public get(key: string): string | number | undefined {
    return this.fields.find((e) => e.key === key)?.value
  }
}
