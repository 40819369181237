import type { components } from '@/types/hypervision_schema'
import type { components as gateway_components } from '@/types/gateway_schema'
import { defineStore } from 'pinia'
import { useHypervisionStore } from './store_hypervision'
import unit_config from '@/data/graphic_units.json'
import { getDateStringYMD } from '@/service/service_date'
import { useGatewayStore } from './store_gateway'

type VariableOrSensor = components['schemas']['Variable'] | components['schemas']['Sensor']
export type Serie = ApexAxisChartSeries[0] & { code: keyof typeof unit_config; unit: string }
export type ChartDeviceData = components['schemas']['Device'] & {
  selected: string[]
  series: Serie[]
  series_comp: Serie[]
}

type ChartBuilderStoreState = {
  id?: number | null
  fetching: boolean
  devices: ChartDeviceData[]
  selected_device?: string
  compare: boolean
  dates: {
    base?: [Date, Date]
    comp?: [Date, Date]
  }
}

export const useChartStore = defineStore('chart', {
  state: (): ChartBuilderStoreState => ({
    fetching: false,
    devices: [],
    compare: false,
    dates: {}
  }),
  getters: {
    getIdString: (state) => {
      return state.id?.toString().padStart(5, '0')
    },
    getDevice: (state) => state.devices.find((e) => e.deviceId === state.selected_device),
    getDevices: (state) => state.devices,
    /**
     * Return the parsed state as Series to be used by apex charts.
     * @returns An array containing all the series
     */
    getSeries: (state) => {
      return (type: 'base' | 'comp'): ApexAxisChartSeries => {
        const output: ApexAxisChartSeries = []
        for (const device of state.devices) {
          if (type === 'base') output.push(...device.series)
          else output.push(...device.series_comp)
        }

        return output
      }
    },
    /**
     * Get the Axis array for the chart
     * @param state
     */
    getAxis: (state) => {
      return (type: 'base' | 'comp'): ApexYAxis[] => {
        const output: ApexYAxis[] = []
        let i = 0
        for (const device of state.devices) {
          let series = type === 'base' ? device.series : device.series_comp
          for (const serie of series) {
            if (output.find((e) => e.seriesName === serie.code)) continue
            const configuration = unit_config[serie.code]
            output.push({
              seriesName: serie.code,
              opposite: configuration.position === 'right',
              decimalsInFloat: 2,
              title: { text: serie.name },
              tooltip: { enabled: true }
            })
          }
        }

        return output
      }
    },
    /**
     * Get the list of available variables for the
     * current selected device
     */
    getOptionsVars: (state): components['schemas']['Variable'][] => {
      if (!state.selected_device) return []
      const store = useHypervisionStore()
      const variables = store.map.var.get(state.selected_device)
      return variables?.filter((e) => e.visible) ?? []
    },
    /**
     * Get the list of available sensors for the
     * current selected device
     */
    getOptionsSens: (state): components['schemas']['Sensor'][] => {
      if (!state.selected_device) return []
      const store = useHypervisionStore()
      const sensors = store.map.sns.get(state.selected_device)
      return sensors?.filter((e) => e.visible) ?? []
    },
    getValue: (state) => {
      return (id: string): VariableOrSensor => {
        const store = useHypervisionStore()
        const variables = Array.from(store.map.var.values())
        const variable = variables.flat().find((e) => e.variableId === id)
        if (variable) return variable

        const sensors = Array.from(store.map.sns.values())
        const sensor = sensors.flat().find((e) => e.sensorId === id)
        if (sensor) return sensor

        throw `ERROR: Value ${id} does not exist.`
      }
    },
    getStringifiedBaseRange: (state): string[] => {
      if (!state.dates.base) {
        return []
      }

      return [state.dates.base?.[0].toJSON(), state.dates.base?.[1].toJSON()]
    },
    getStringifiedCompRange: (state): string[] | null => {
      if (!state.dates.comp || state.dates.comp === null || !state.compare) {
        return null
      }

      return [state.dates.comp?.[0].toJSON(), state.dates.comp?.[1].toJSON()]
    },
    /**
     * Checks if there is any select value in the devices list
     * @param state
     */
    isVisible: (state) => {
      for (const device of state.devices) {
        if (device.selected.length > 0) return true
      }

      return false
    }
  },
  actions: {
    async fetch(device_data?: ChartDeviceData) {
      let device = device_data
      if (!device_data) device = this.getDevice
      if (!this.dates.base || !device) return

      const store = useHypervisionStore()
      const date_start = getDateStringYMD(this.dates.base[0])
      const date_end = getDateStringYMD(this.dates.base[1])
      this.fetching = true

      const series = []
      const series_comp = []
      for (const value of device.selected) {
        const measures = await store.fetchMeasures(device.deviceId!, value, date_start, date_end)
        if (!measures.data) continue

        const element = this.getValue(value)
        const unit = store.map.uni.get(element.unitId)
        if (!unit) continue

        const code = unit.code as keyof typeof unit_config
        const configuration = unit_config[code]
        series.push({
          name: `${element.name} (${unit.symbol})`,
          type: configuration.type === 'Line' ? 'line' : 'column',
          code: code,
          unit: unit.unitId!,
          data: measures.data.map((e) => ({
            x: new Date(e.measureDate).getTime(),
            y: e.decimalValue ?? 0
          }))
        })

        if (this.compare && this.dates.comp) {
          const date_start = getDateStringYMD(this.dates.comp[0])
          const date_end = getDateStringYMD(this.dates.comp[1])

          const measures = await store.fetchMeasures(device.deviceId!, value, date_start, date_end)
          if (!measures.data) continue

          const element = this.getValue(value)
          const unit = store.map.uni.get(element.unitId)
          if (!unit) continue

          const code = unit.code as keyof typeof unit_config
          const configuration = unit_config[code]
          series_comp.push({
            name: `${element.name} (${unit.symbol})`,
            type: configuration.type === 'Line' ? 'line' : 'column',
            code: code,
            unit: unit.unitId!,
            data: measures.data.map((e) => ({
              x: new Date(e.measureDate).getTime(),
              y: e.decimalValue ?? 0
            }))
          })
        }
      }

      device.series = series
      device.series_comp = series_comp
      this.fetching = false
    },
    async fetchAll() {
      return new Promise<void>(async (resolve) => {
        for (const device of this.devices) {
          await this.fetch(device)
        }
        resolve()
      })
    },
    select(id: string) {
      const index = this.getDevice?.selected.findIndex((e) => e === id)
      if (index === undefined) return
      if (index === -1) this.getDevice?.selected.push(id)
      else this.getDevice?.selected.splice(index, 1)
    },
    setDevice(device: components['schemas']['Device']) {
      this.selected_device = device.deviceId
    },
    addDevice(device: components['schemas']['Device']) {
      this.devices.push({
        ...device,
        selected: [],
        series: [],
        series_comp: []
      })
      this.selected_device = device.deviceId
    },
    removeDevice(device: components['schemas']['Device']) {
      this.devices = this.devices.filter((d) => d.deviceId !== device.deviceId)
    },
    init() {
      const store = useHypervisionStore()
      this.id = undefined
      this.devices = []
      this.dates.base = [new Date(store.getMonth.first), new Date(store.getMonth.last)]
      this.dates.comp = undefined
      this.compare = false
      this.selected_device = undefined
    },
    load(chart: gateway_components['schemas']['ChartDTO']) {
      const store = useHypervisionStore()

      this.id = chart.id
      const dates_base: [Date, Date] = [
        new Date(chart.range_base[0]),
        new Date(chart.range_base[1])
      ]
      this.dates.base = dates_base

      if (chart.range_comp) {
        const dates_comp: [Date, Date] = [
          new Date(chart.range_comp[0]),
          new Date(chart.range_comp[1])
        ]
        this.dates.comp = dates_comp
        this.compare = true
      } else {
        this.dates.comp = undefined
      }

      this.devices = []

      for (const device_id of chart.devices) {
        const device = store.map.dev.get(device_id)
        if (!device) continue

        const device_vars = store.map.var.get(device.deviceId!)
        const device_sens = store.map.sns.get(device.deviceId!)

        const chart_device: ChartDeviceData = {
          ...device,
          selected: [],
          series: [],
          series_comp: []
        }

        for (const variable of chart.variables) {
          if (device_vars?.find((e) => e.variableId === variable)) {
            chart_device.selected.push(variable)
          }
        }

        for (const sensor of chart.sensors) {
          if (device_sens?.find((e) => e.sensorId === sensor)) {
            chart_device.selected.push(sensor)
          }
        }

        this.devices.push(chart_device)
      }

      this.selected_device = this.devices[0].deviceId
      return this.fetchAll()
    },
    save() {
      const store = useHypervisionStore()
      const gateway = useGatewayStore()
      this.fetching = true

      const chart: gateway_components['schemas']['ChartDTO'] = {
        id: this.id,
        devices: [],
        sensors: [],
        variables: [],
        range_base: this.getStringifiedBaseRange,
        range_comp: this.getStringifiedCompRange
      }

      for (const device of this.devices) {
        const device_vars = store.map.var.get(device.deviceId!)
        for (const value of device.selected) {
          if (device_vars?.find((e) => e.variableId === value)) {
            chart.variables.push(value)
          } else {
            chart.sensors.push(value)
          }
        }

        chart.devices.push(device.deviceId!)
      }

      if (chart.id === undefined) {
        gateway.postChart(chart).finally(() => {
          this.fetching = false
        })
      } else {
        gateway.patchChart(chart.id!, chart).finally(() => {
          this.fetching = false
        })
      }
    }
  }
})
