import { useCookies } from '@/components/composable/composable_cookie'
import type { components, paths } from '@/types/hypervision_schema'
import createClient from 'openapi-fetch'
import { defineStore } from 'pinia'
import router from '@/router'
import { parse_jwt } from '@/service/service_security'
import { getMonthInterval } from '@/service/service_date'
import visibilities from '@/data/visibilities.json'
import {
  create_application_tree,
  type ApplicationTreeNode,
  search,
  create_application_map,
  type ApplicationDevice
} from '@/service/service_unit'
import {
  calculate_total,
  get_device_values,
  get_device_values_month,
  get_device_year_values,
  type DeviceValue,
  type DeviceValues
} from '@/service/service_calculations'
import AttributeHelper from '@/service/service_attributes'
import special_data from '@/data/sensors.json'
import CustomFieldHelper from '@/service/service_customfields'

export enum PipelineStates {
  DONE,
  WASM,
  FTCH,
  CALC,
  FAIL
}

/**
 * RAW Data queried from the API
 */
export type HypervisionStoreRawData = {
  org: components['schemas']['OrganizationUnit'][]
  dev: components['schemas']['Device'][]
  dpa: components['schemas']['DeviceParameter'][]
  oty: components['schemas']['OrganizationUnitType'][]
  usr: components['schemas']['User'] | null
}

type TooglableEntity = {
  visible: boolean
}

/**
 * MAP Data, mostly contains calculated values for
 * the application
 */
export type HypervisionStoreMapData = {
  org: Map<string, ApplicationTreeNode>
  dev: Map<string, ApplicationDevice>
  att: Map<string, AttributeHelper>
  uni: Map<string, components['schemas']['Unit']>
  sns: Map<string, (components['schemas']['Sensor'] & TooglableEntity)[]>
  var: Map<string, (components['schemas']['Variable'] & TooglableEntity)[]>
}

type HypervisionStoreState = {
  raw: HypervisionStoreRawData
  map: HypervisionStoreMapData
  tree?: ApplicationTreeNode
  pipeline_state: PipelineStates
  authenticated: boolean
  current_month: Date
}

const client = createClient<paths>({
  baseUrl: '/api'
})

const gateway = createClient<paths>({
  baseUrl: '/api'
})

function hasJWT(): boolean {
  const { getCookieValue } = useCookies()
  const cookie = getCookieValue('hv_jwt')
  return cookie.value ? true : false
}

export const useHypervisionStore = defineStore('hypervision', {
  state: (): HypervisionStoreState => ({
    raw: {
      org: [], // Organization Units
      dev: [], // Devices
      dpa: [], // Devices Parameters
      oty: [], // Organization Unit Types
      usr: null // User data
    },
    map: {
      org: new Map(), // Organization Units
      uni: new Map(), // Units
      dev: new Map(), // Devices
      var: new Map(), // Device Variables
      att: new Map(), // Organization Unit Attributes
      sns: new Map() // Device Sensors
    },
    tree: undefined,
    pipeline_state: PipelineStates.DONE,
    authenticated: hasJWT(),
    current_month: new Date()
  }),
  getters: {
    getUser: (state) => {
      return state.raw.usr
    },
    getPipelineState: (state) => {
      return state.pipeline_state
    },
    getPipelineMessage: (state) => {
      switch (state.pipeline_state) {
        case PipelineStates.WASM:
          return 'Initialisation du moteur..'
        case PipelineStates.FTCH:
          return 'Récupération des données..'
        case PipelineStates.CALC:
          return 'Calcul des valeurs..'
        case PipelineStates.FAIL:
          return 'Une erreur est survenue.'
        case PipelineStates.DONE:
          return 'Terminé.'
      }
    },
    getJWT: (_) => {
      const { getCookieValue } = useCookies()
      const cookie = getCookieValue('hv_jwt')
      return cookie
    },
    getRoot: (state) => {
      return state.tree
    },
    getNode: (state) => {
      return (node_id: string) => {
        if (!state.tree) return undefined
        return search(state.tree, node_id)
      }
    },
    getNodeParent: (state) => {
      return (node: ApplicationTreeNode) => {
        if (!node.parentOrganizationUnitId || !state.tree) return undefined
        return search(state.tree, node.parentOrganizationUnitId)
      }
    },
    getNodeDevices: (state) => {
      return (node: ApplicationTreeNode): ApplicationDevice[] => {
        const output: ApplicationDevice[] = []
        for (let i = 0; i < state.raw.dev.length; i++) {
          const device = state.raw.dev[i]
          if (device.organizationUnitId === node.organizationUnitId) {
            const application_device = state.map.dev.get(device.deviceId!)
            if (application_device) {
              output.push(application_device)
            }
          }
        }

        return output
      }
    },
    getNodeDevicesRecursive: (state) => {
      return (node: ApplicationTreeNode): ApplicationDevice[] => {
        const recursive_query = (parent: ApplicationTreeNode, output: ApplicationDevice[]) => {
          const devices = state.raw.dev.filter(
            (device) => device.organizationUnitId === parent.organizationUnitId
          )

          devices.forEach((device) => {
            const application_device = state.map.dev.get(device.deviceId!)
            if (application_device) {
              output.push(application_device)
            }
          })

          parent.childrens.forEach((children) => {
            recursive_query(children, output)
          })
        }

        const output: ApplicationDevice[] = []
        recursive_query(node, output)
        return output
      }
    },
    getNodeAttributes: (state) => {
      return (node: ApplicationTreeNode): AttributeHelper => {
        const attributes = state.map.att.get(node.organizationUnitId!)
        if (!attributes) {
          const new_attributes = new AttributeHelper(node.customAttributes)
          state.map.att.set(node.organizationUnitId!, new_attributes)
          return new_attributes
        }

        return attributes as AttributeHelper
      }
    },
    getMonth: (state): { first: string; last: string } => {
      return getMonthInterval(state.current_month)
    },
    getDay: (): { first: string; last: string } => {
      const date_start = new Date()
      const date_end = new Date()
      date_start.setDate(date_start.getDate() - 1)

      const start_string = date_start.toISOString().substring(0, 10)
      const end_string = date_end.toISOString().substring(0, 10)
      return { first: start_string, last: end_string }
    },
    getTotal: (state) => {
      return (type?: string) => {
        return calculate_total(state.map.dev, type)
      }
    },
    getTotalRange: (state) => {
      return async (start: Date, end: Date, filter?: string) => {
        const map = new Map<string, DeviceValues[]>()
        for (const device of state.raw.dev) {
          if (filter && device.type !== filter) continue
          const variables = state.map.var.get(device.deviceId!)
          if (variables) {
            const values = await get_device_values_month(
              device,
              variables,
              start.toISOString().substring(0, 10),
              end.toISOString().substring(0, 10)
            )

            map.set(device.deviceId!, values)
          }
        }

        return map
      }
    },
    getDeviceList: (state): { name: string; id: string }[] => {
      const output = []
      for (let i = 0; i < state.raw.dev.length; i++) {
        const device = state.raw.dev[i]
        const device_unit = state.map.org.get(device.organizationUnitId)
        const name = `${device_unit?.code} - ${device.name}`
        output.push({ id: device.deviceId ?? '', name })
      }

      return output
    },
    isVisible: () => {
      return (id: string) => {
        return visibilities.includes(id)
      }
    }
  },
  actions: {
    /**
     * Initialize the application data pipeline
     * This will fetch current month data, execute calculations
     * and setup the base layer for the store
     */
    async pipeline_init() {
      try {
        // WASM engine initialization (unused for now)
        // this.pipeline_state = PipelineStates.WASM
        // await wasm_init()

        // Fetching data from bluetek APIs
        this.pipeline_state = PipelineStates.FTCH
        const request_stack = await Promise.all([
          this.fetchUser(),
          this.fetchOrganizationUnits(),
          this.fetchOrganizationUnitTypes(),
          this.fetchDeviceParameters(),
          this.fetchDevices(),
          this.fetchUnits()
        ])

        // Checking if all the responses are correct
        for (const response of request_stack) {
          if (response.data === undefined) {
            throw response.error
          }
        }

        this.raw.usr = request_stack[0].data!
        this.raw.org = request_stack[1].data!
        this.raw.oty = request_stack[2].data!
        this.raw.dpa = request_stack[3].data!
        this.raw.dev = request_stack[4].data!

        for (const unit of request_stack[5].data!) {
          this.map.uni.set(unit.unitId!, unit)
        }

        await this.fetchDevicesDatas()

        // Calculating local data and building tree
        this.pipeline_state = PipelineStates.CALC
        this.map.org = create_application_map(this.raw)

        this.tree = create_application_tree(this.map.org)

        this.pipeline_state = PipelineStates.DONE
      } catch (e) {
        this.pipeline_state = PipelineStates.FAIL
        throw e
      }
    },
    /**
     * Authenticate to the hypervision REST API.
     * Sets the hv_jwt cookie if successful.
     * @param login
     * @param password
     */
    authenticate(tenantCode: string, login: string, password: string, redirect?: string) {
      const response = client.POST('/api/GetToken', {
        parseAs: 'text',
        params: {
          query: {
            tenantCode,
            login,
            password
          }
        }
      })

      response.then((response) => {
        if (response.data) {
          const { setCookieValue } = useCookies()

          const cookie_value = parse_jwt(response.data)

          setCookieValue('hv_jwt', response.data, new Date(cookie_value.exp * 1000))

          this.authenticated = true
          router.push(redirect ?? '/dashboard')
        }
      })
      return response
    },
    /**
     * Logout the current authenticated user
     * clear the authentication cookie and redirect to
     * the autentication page
     */
    logout() {
      if (this.authenticated) {
        const { deleteCookieValue } = useCookies()
        deleteCookieValue('hv_jwt')
        this.authenticated = false
        router.push('/')
      }
    },
    /**
     * Fetch logged in user data
     * @returns
     */
    fetchUser() {
      const request = client.GET('/api/Users/WhoAmi', {
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })

      return request
    },
    /**
     * Retreive all units of the current logged in user
     */
    fetchUnits() {
      const request = client.GET('/api/Units', {
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })
      return request
    },
    /**
     * Retreive all organization units of the current
     * logged in user
     *
     * This function also sets the root unit if any organization unit
     * have a parent unit id set to **null**
     */
    fetchOrganizationUnits() {
      const request = client.GET('/api/OrganizationUnits', {
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })
      return request
    },
    fetchOrganizationUnitDevicesById(organization_unit_id: string) {
      const request = client.GET('/api/Devices/{OrganizationUnitId}', {
        params: {
          path: {
            OrganizationUnitId: organization_unit_id
          }
        },
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })

      return request
    },
    /**
     * Retreive devices from the logged in User
     */
    fetchDevices() {
      const request = client.GET('/api/Devices', {
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })
      return request
    },
    fetchDeviceParameters() {
      const request = client.GET('/api/DeviceParameters', {
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })
      return request
    },
    /**
     * Retreive variables from a device ID
     */
    fetchDeviceVariables(device_id: string) {
      const request = client.GET('/api/Variables', {
        params: {
          query: {
            deviceId: device_id
          }
        },
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })
      return request
    },
    /**
     * Retreive measures from a variable ID
     */
    fetchMeasuresVariable(variable_id: string, start: string, end: string) {
      const request = client.GET('/api/Measures/Variable', {
        params: {
          query: {
            variableId: variable_id,
            dateDebut: start,
            dateFin: end
          }
        },
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })
      return request
    },
    /**
     * Fetch all devices datas for the current month period
     *
     * **Warning**: This is a heavy cost method, this might be improved
     * in the future by bluetek API structure.
     *
     * @requires this.raw.dev Requires raw device data to be queried
     */
    async fetchDevicesDatas() {
      if (this.raw.dev.length === 0) {
        throw 'HYPERVISION STORE: Missing raw.dev data before fetching. Consider retreiving data before calling this function'
      }

      const promises = []
      for (const device of this.raw.dev) {
        promises.push(this.fetchDeviceVariables(device.deviceId!))
      }

      const responses = await Promise.all(promises)
      for (const index in responses) {
        const response = responses[index]
        const device = this.raw.dev[index]

        const application_device: ApplicationDevice = {
          ...device,
          alerts: new Map(),
          values: [],
          year: []
        }

        if (response.data) {
          this.map.var.set(
            device.deviceId!,
            response.data.map((e) => {
              const attributes = new CustomFieldHelper(e.customFields)
              const visisbility = attributes.get('visible')
              return { ...e, visible: visisbility === 1 }
            })
          )
          application_device.values = await get_device_values(device, response.data)
          application_device.year = await get_device_year_values(device, response.data)
        }

        const device_sensors = await this.fetchSensors(device.deviceId!)
        if (device_sensors.data) {
          this.map.sns.set(
            device.deviceId!,
            device_sensors.data.map((e) => {
              const attributes = new CustomFieldHelper(e.customFields)
              const visisbility = attributes.get('visible')
              return { ...e, visible: visisbility === 1 }
            })
          )

          // TODO Load alerts
          // Currently hard codded logic will happen here
          for (const sensor of device_sensors.data) {
            if (sensor.sensorId === 'f049a13e-fbf7-4154-a1ea-d9ee1910f901') {
              application_device.alerts.set('f049a13e-fbf7-4154-a1ea-d9ee1910f901', {
                title:
                  'Attention l’humidité de la toiture est inférieur au seuil de confort. Vérifiez votre programme d’arrosage.',
                date: new Date(2024, 0, 20)
              })
            }
          }
        }

        this.map.dev.set(device.deviceId!, application_device)
      }
    },
    /**
     * Retreive organization unit types
     * from the logged in User
     */
    fetchOrganizationUnitTypes() {
      const request = client.GET('/api/OrganizationUnitTypes', {
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })
      return request
    },
    /**
     * Retreive sensors from a given device id
     * @param device_id ID of the device
     */
    fetchSensors(device_id: string) {
      const request = client.GET('/api/Sensors', {
        params: {
          query: {
            deviceId: device_id
          }
        },
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })
      return request
    },
    /**
     * Retreive measures from an ID of a sensor or variable
     * @param id ID of the sensor
     * @param start DD-MM-YYYTHH:MM:SS
     * @param end DD-MM-YYYTHH:MM:SS
     */
    fetchMeasures(device_id: string, id: string, start: string, end: string) {
      const isvar = this.map.var.get(device_id)?.find((e) => e.variableId === id) ? true : false
      if (isvar) {
        return this.fetchMeasuresVariable(id, start, end)
      }

      return this.fetchMeasuresSensor(id, start, end)
    },
    /**
     * Retreive measures from a given sensor id
     * @param sensor_id ID of the sensor
     * @param start DD-MM-YYYTHH:MM:SS
     * @param end DD-MM-YYYTHH:MM:SS
     */
    fetchMeasuresSensor(sensor_id: string, start: string, end: string) {
      const request = client.GET('/api/Measures/Acquisition', {
        params: {
          query: {
            sensorId: sensor_id,
            dateDebut: start,
            dateFin: end
          }
        },
        headers: {
          Authorization: `Bearer ${this.getJWT.value}`
        }
      })

      return request
    },
    fetchUserWaterPrice() {
      return null
    },
    updateDeviceParameters() {
      const hypervision_store = useHypervisionStore()
      for (const parameter of this.raw.dpa) {
        parameter.updatedBy = undefined
        parameter.updatedAt = undefined
        parameter.createdAt = undefined
        parameter.createdBy = undefined
        const request = client.PUT('/api/DeviceParameters/{id}', {
          params: {
            path: {
              id: parameter.deviceParameterId!
            }
          },
          body: parameter
        })
      }
    }
  }
})
