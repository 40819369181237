<template>
  <div class="accordion" :class="{ open: isOpen }">
    <div class="accordion-header" :class="{ open: isOpen }" @click="toggleAccordion">
      <h4>
        {{ node.name }}
      </h4>
      <ChevronDown class="icon" />
    </div>
    <div class="accordion-body" :class="{ open: isOpen }">
      <CommonAccordion
        v-for="children in node.childrens"
        :node="children"
        v-bind:key="children.organizationUnitId"
        @select="(e) => emits('select', e)"
      />
      <div class="device" v-for="device in store.getNodeDevices(node)" v-bind:key="device.deviceId">
        {{ device.name }}
        <CommonButton class="btn btn-secondary small" @click="emits('select', device)">
          Ajouter
        </CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { ChevronDown } from 'lucide-vue-next'
import type { ApplicationTreeNode } from '@/service/service_unit'
import { useHypervisionStore } from '@/stores/store_hypervision'
import CommonButton from './CommonButton.vue'
import { useChartStore } from '@/stores/store_chart'
import type { components } from '@/types/hypervision_schema'

defineProps<{
  node: ApplicationTreeNode
}>()

const store = useHypervisionStore()
const store_builder = useChartStore()
const isOpen = ref(false)

const toggleAccordion = () => {
  isOpen.value = !isOpen.value
}

const emits = defineEmits<{
  (e: 'select', value: components['schemas']['Device']): void
}>()
</script>

<style lang="scss" scoped>
.accordion {
  background: var(--vc-gray1);
  border-radius: var(--br-small);
  padding: var(--spacer) var(--spacer) 0;
  margin-bottom: var(--spacer-0_5);

  &-header {
    min-width: 100%;
    cursor: pointer;
    display: flex;
    max-width: fit-content;
    gap: var(--spacer);
    border-bottom: 1px solid var(--vc-gray2);
    padding: 10px;
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;

    .icon {
      transform: rotate(0);
      transition: transform 0.3s ease-in-out;
    }

    &.open {
      margin-bottom: var(--spacer-0_5);
      background-color: var(--vc-gray2);

      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &-body {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    margin-left: var(--spacer);

    &.open {
      max-height: 500px;
    }

    & > .accordion {
      &.open {
        margin-bottom: var(--spacer-0_5);
      }
      padding: 0;
    }

    .device {
      display: flex;
      align-items: center;
      gap: var(--spacer-0_5);
      margin-bottom: var(--spacer-0_5);
    }
  }
}

.mobile {
  .accordion-body {
    margin-left: var(--spacer-0_5);
  }
}
</style>
